import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Checkbox, FormControlLabel, Grid, Modal } from "@mui/material";
import UserDetailsCard from "./UserDetailsCard";
import ModalMainContainer from "../ModalNotification/ModalMainContainer";
import ApprovarHeader from "./ApprovarHeader";
import Workflow from "./Workflow";
import Product from "./Product";
import Trainings from "./Trainings";
import SponserAndSupervisor from "./SponserAndSupervisor";
import CommentsCard from "./CommentsCard";
import SelectedRolesCard from "./SelectedRolesCard";
import SelectedProductsCard from "./Product/SelectedProductsCard";
import SelectedSponsorPopup from "../../components/common/SelectedSponsorPopup";
import ButtonError from "./ButtonError";
import ButtonSuccess from "./ButtonSuccess";
import { useDispatch, useSelector } from "react-redux";

import { approvar_actionTypes } from "../utils/approval_actionType";

import "./styles/Approval.css";
import {
  getAllRolesAndProducts,
  getSponsorDetailsSuccess,
  requestSelectedRolesSuccess,
  updateApprovarRequestData
} from "../../redux/actions/approvar-actions/approvar-action";
import { submitApprovarRequest } from "../../redux/actions/approvar-actions/submitApprovarRequest-action";
import { approvar_actionType } from "../../redux/actionTypes/approvar-actionTypes/approvar-actionType";
import { submitApprovarRequest_actionType } from "../../redux/actionTypes/approvar-actionTypes/submitApprovarRequest-actionType";
// import { axiosMsGraph } from "../../api";

// This will replace after api integration
// const initinalState = {
//   sponserDetails: {
//     // loginId: "123456",
//     // name: "David Clark",
//     // email: "david.clark@lilly.com"
//   }
// };

const ModalStyle = {
  // disply:'flex',
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: "90%",
  pedding: "0px",
  // height: "30%",
  borderRadius: "10px",
  backgroundColor: "white"
};

export default function ApprovarScreen({ actionType, id }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [editRequest, setEditRequest] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitActive, setIsSubmitActive] = useState(false);
  const [modalMessage, setModalMessage] = useState([]);
  const [disableApproveBtn, setDisableApproveBtn] = useState(true);
  const [editSponser, setEditSponser] = useState(false);
  const [isSubmitSuceess, setIsSubmitSuccess] = useState(false);
  const [disableReassignBtn, setDisableReassignBtn] = useState(true);
  const [isTrainingConfirmed, setIsTrainingConfirmed] = useState(false);
  const [isConsentChecked, setIsConsentChecked] = useState(false);
  const {
    reducerData,
    users_access_payload,
    sponsorData,
    taskId,
    completeState,
    actionButtonState,
    loading,
    userDetails,
    trainings,
    hubsData
  } = useSelector((state) => ({
    reducerData: state.ApprovarReducer.data,
    users_access_payload:
      state.ApprovarReducer.data?.vam_request?.request_payload
        ?.users_access_payload,
    taskId: state.ApprovarReducer.data?.taskId,
    completeState: state,
    actionButtonState: state.ApprovarReducer?.actionButtonState,
    loading: state.ApprovarReducer.loading,
    userDetails: state.ApprovarReducer.data?.userDetails,
    trainings: state.ApprovarReducer?.trainings,
    hubsData: state.ApprovarReducer?.hubsData,
    sponsorData: state.ApprovarReducer?.sponsorData
  }));

  const userType = userDetails?.email?.toLowerCase()?.includes("@lilly.com")
    ? "Lilly"
    : "Non-Lilly";
  const [approverComments, setApprovarComments] = useState(
    reducerData.approverComments
  );

  const { isRequesetComplete } = useSelector((state) => ({
    isRequesetComplete: state.SubmitApprovarRequestReducer.isRequesetComplete
  }));
  const { isEditOrUpdateFailed } = useSelector((state) => ({
    isEditOrUpdateFailed: state.ApprovarReducer?.isEditOrUpdateFailed
  }));
  // console.log(isEditOrUpdateFailed);

  // useEffect(() => {

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  useEffect(() => {
    // bellow should be uncomment at the end
    if (
      !loading &&
      actionButtonState?.isRejectHidden &&
      actionButtonState?.isReassignedHidden &&
      actionButtonState?.isApproveHidden
    ) {
      navigate("/access-denied");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, actionButtonState]);

  useEffect(() => {
    if (actionType === approvar_actionTypes.CONFIRM_TRAINING_COMPLETION) {
      if (isTrainingConfirmed) {
        setDisableApproveBtn(false);
      } else {
        setDisableApproveBtn(true);
      }
    } else if (isConsentChecked) {
      setDisableApproveBtn(false);
    } else {
      setDisableApproveBtn(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConsentChecked, isTrainingConfirmed]);

  useEffect(() => {
    if (sponsorData?.employeeId && disableReassignBtn) {
      setDisableReassignBtn(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sponsorData?.employeeId]);

  const handleRejectButton = () => {
    handleOpen(true);
    setIsSubmitActive(false);
    setModalMessage(["Are you sure you want to reject this task?"]);
  };

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const onHandleEditRequest = () => {
    dispatch(
      getAllRolesAndProducts(
        hubsData,
        completeState.ApprovarReducer.selectedProducts,
        userType
      )
    );
    setEditRequest(true);
  };

  const handleEditSponser = () => {
    setEditSponser(true);
  };

  const handleClose = () => {
    setEditSponser(false);
  };

  const onSaveHandler = () => {
    dispatch(updateApprovarRequestData(id, completeState));
  };

  const handleSetSponsor = (selectedSponsor) => {
    const newSponserDetails = {
      employeeId: selectedSponsor?.empId,
      displayName: selectedSponsor?.name,
      mail: selectedSponsor?.email
    };
    // setChangeSponsor(true);
    // setGlobalState({ ...globalState, sponserDetails: newSponserDetails });
    dispatch(getSponsorDetailsSuccess(newSponserDetails));
    setDisableReassignBtn(false);
  };

  const onCommentsHandler = (e) => {
    setApprovarComments(e.target.value);
  };

  const handleReactivate = () => {
    setIsModalOpen(false);
    submitRequest("reject");
  };

  useEffect(() => {
    if (isRequesetComplete) {
      setIsSubmitActive(true);
      if (completeState.SubmitApprovarRequestReducer.isSubmitSuccess) {
        setIsSubmitSuccess(true);
      } else {
        setIsSubmitSuccess(false);
      }
      setModalMessage(completeState.SubmitApprovarRequestReducer.message);
      setTimeout(() => {
        handleOpen(true);
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRequesetComplete]);

  useEffect(() => {
    if (isEditOrUpdateFailed) {
      setIsSubmitActive(true);
      setIsSubmitSuccess(false);
      setModalMessage([completeState.ApprovarReducer?.error]);
      handleOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditOrUpdateFailed]);

  const handleSubmitRequest = (event) => {
    const requestType = event.target.id;
    submitRequest(requestType);
  };

  const submitRequest = (requestType) => {
    const loggedUserMail =
      completeState?.LoggedUserReducer?.loggedUser?.username;
    const requestdUserMail = userDetails?.email;
    if (loggedUserMail.trim().toLowerCase() === requestdUserMail.trim().toLowerCase()) {
      dispatch({
        type: submitApprovarRequest_actionType.APPROVE_OWN_REQUEST_FAILURE
      });
      return;
    }
    const taskType = reducerData?.taskType;
    let requestedData;
    let actionData;
    requestedData = {
      request_id: id,
      action_type: requestType,
      update_type: "approval"
    };
    if (requestType === "reassign") {
      actionData = {
        taskAction: "reassign",
        taskAssignedBy: completeState.LoggedUserReducer.loggedUser.username,
        taskAssignedTo: sponsorData?.mail
      };
    } else {
      actionData = {
        taskAction: requestType,
        taskAssignedTo: reducerData?.taskAssignedTo,
        approverComments: approverComments
      };
    }
    dispatch(
      submitApprovarRequest(
        taskId,
        { ...requestedData, ...actionData },
        requestType,
        taskType
      )
    );
  };

  const renderSelectedRolesAndProducts = () => {
    return (
      <>
        {actionType === approvar_actionTypes.ALL_IN_ONE ? (
          <Grid item sm={12} md={6} xs={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%"
              }}
            >
              <div style={{ flex: "30%" }}>
                <SelectedRolesCard
                  cardHeight={"142px"}
                  workflow={users_access_payload?.name}
                  countries={users_access_payload?.vvpm_countries}
                  selectedRoles={users_access_payload?.vam_roles}
                />
              </div>
              <div style={{ flex: "70%" }}>
                <Trainings
                  style={{ height: "100%" }}
                  editRequest={editRequest}
                  trainings={trainings}
                  actionType={actionType}
                  isTrainingConfirmed={isTrainingConfirmed}
                  onTrainingConfirmation={() => {
                    setIsTrainingConfirmed((prevState) => !prevState);
                  }}
                />
              </div>
            </div>
          </Grid>
        ) : (
          <Grid item sm={12} md={6} xs={12}>
            <Grid container spacing={0}>
              {/* Roles & Products Section */}
              <SelectedRolesCard
                workflow={users_access_payload?.name}
                selectedRoles={users_access_payload?.vam_roles}
              />
              {/* Selected Products */}
              <SelectedProductsCard
                selectedProducts={users_access_payload?.vvpm_products}
              />
            </Grid>
          </Grid>
        )}
        {actionType === approvar_actionTypes.ALL_IN_ONE ? (
          <Grid item sm={12} md={6} xs={12} style={{ paddingTop: "0px" }}>
            {/* <Grid container spacing={0} > */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%"
              }}
            >
              <div style={{ flex: "30%" }}>
                <SelectedProductsCard
                  style={{}}
                  selectedProducts={users_access_payload?.vvpm_products}
                />
              </div>
              <div style={{ flex: "70%" }}>
                <SponserAndSupervisor
                  actionType={actionType}
                  cardHeight={"600px"}
                  style={{}}
                  isEditable={actionButtonState?.isReassignEnabled || false}
                  editRequest={editRequest}
                  sponserDetails={sponsorData}
                  handleEditSponser={handleEditSponser}
                />
              </div>
            </div>
            {/* </Grid> */}
          </Grid>
        ) : (
          <Grid item sm={12} md={6} xs={12} style={{ paddingTop: "0px" }}>
            {actionType === approvar_actionTypes.REASSIGN_SUPERVISOR ? (
              <SponserAndSupervisor
                actionType={actionType}
                editRequest={editRequest}
                sponserDetails={sponsorData}
                handleEditSponser={handleEditSponser}
                isEditable={actionButtonState?.isReassignEnabled || false}
              />
            ) : (
              <Trainings
                editRequest={editRequest}
                trainings={trainings}
                actionType={actionType}
                isTrainingConfirmed={isTrainingConfirmed}
                onTrainingConfirmation={() => {
                  setIsTrainingConfirmed((prevState) => !prevState);
                }}
              />
            )}
          </Grid>
        )}
        {/* Requester Comments */}
        <CommentsCard
          name="requesterComments"
          label="Requestor Comments"
          value={reducerData?.vam_request?.requester_comments}
          isDisabled={true}
          approvarComments={false}
        />

        {/* Approvar Comments */}
        <CommentsCard
          name="approvarComments"
          label="Approver Comments"
          value={approverComments}
          // value={globalState.approvar_comments}
          onChangeHandler={onCommentsHandler}
          approvarComments={true}
        />
      </>
    );
  };

  const renderEditWorkflowAndProducts = () => {
    return (
      <>
        <Workflow
          hubname={users_access_payload?.name}
          selectedRoles={completeState.ApprovarReducer?.selectedRoles}
          allRoles={completeState.ApprovarReducer?.allRoles}
        />
        <Product />
      </>
    );
  };

  const renderConfirmCheckBox = () => {
    return (
      <div className="confirmCheckBoxContainer">
        <FormControlLabel
          control={
            <Checkbox
              checked={isConsentChecked}
              onChange={(event) => {
                console.log("event", event);
                const { checked } = event.target;
                setIsConsentChecked(checked);
              }}
              name="check"
              sx={{
                "&.Mui-checked": {
                  color: "#00AE43"
                }
              }}
            />
          }
          label={
            <span
              style={{
                fontSize: "14px",
                fontStyle: "italic"
              }}
            >
              {actionType === approvar_actionTypes.ALL_IN_ONE
                ? "I have confirmed that the access is appropriate and consistent with required job functionalities, also confirmed that all trainings are completed by user."
                : "I have confirmed that the access is appropriate and consistent with required job functionalities."}
            </span>
          }
        />
      </div>
    );
  };

  return (
    <>
      <div className="homeContainer">
        <ApprovarHeader
          handleEditRequest={onHandleEditRequest}
          actionType={actionType}
          editRequest={editRequest}
          isEditEnabled={
            actionButtonState?.isEditEnabled &&
            reducerData?.taskStatus === "active"
          }
          isEditHidden={actionButtonState?.isEditHidden}
          id={reducerData.vam_request.request_id}
          requestType={reducerData.vam_request.requestType}
        />
        <div className="homeContainerCards">
          <div className="card">
            <div>
              <Grid
                container
                spacing={0}
                className="accountSumary_parentSection"
              >
                {/* User Details Section */}
                <UserDetailsCard userDetails={reducerData?.userDetails} />

                {editRequest
                  ? renderEditWorkflowAndProducts()
                  : renderSelectedRolesAndProducts()}
              </Grid>
            </div>
          </div>
        </div>
        {!editRequest &&
          actionType !== approvar_actionTypes.CONFIRM_TRAINING_COMPLETION &&
          renderConfirmCheckBox()}
        <div className="homeContainerButtons">
          {editRequest ? (
            <>
              <ButtonError
                label="Cancel"
                disabled={false}
                onClickHandler={() => {
                  dispatch(
                    requestSelectedRolesSuccess(users_access_payload.vam_roles)
                  );
                  setEditRequest(false);
                }}
              />
              <ButtonSuccess
                label="Save"
                disabled={false}
                onClickHandler={onSaveHandler}
              />
            </>
          ) : (
            <>
              {actionButtonState?.isRejectHidden === false &&
                !actionButtonState?.isRejectHidden && (
                  <ButtonError
                    id="reject"
                    label="Reject"
                    disabled={
                      !actionButtonState?.isRejectEnabled ||
                      reducerData?.taskStatus !== "active" ||
                      !approverComments
                    }
                    onClickHandler={handleRejectButton}
                  />
                )}
              {/* {actionType ===
                approvar_actionTypes.APPROVE_PRODUCTS_AND_ROLES && (
                  <ButtonSuccess
                    label="Save & Close"
                    disabled={false}
                    width={140}
                    onClickHandler={() => { }}
                  />
                )} */}
              {!actionButtonState?.isReassignedHidden &&
                (actionType === approvar_actionTypes.REASSIGN_SUPERVISOR ||
                  actionType === approvar_actionTypes.ALL_IN_ONE) && (
                  <ButtonSuccess
                    id="reassign"
                    label="Reassign"
                    disabled={
                      disableReassignBtn ||
                      !actionButtonState?.isReassignEnabled ||
                      reducerData?.taskStatus !== "active"
                    }
                    onClickHandler={(evt) => handleSubmitRequest(evt)}
                  />
                )}
              {!actionButtonState?.isApproveHidden && (
                <ButtonSuccess
                  id="approve"
                  label="Approve"
                  disabled={
                    !actionButtonState?.isApproveEnabled ||
                    disableApproveBtn ||
                    reducerData?.taskStatus !== "active" ||
                    !approverComments
                  }
                  onClickHandler={(evt) => handleSubmitRequest(evt)}
                />
              )}
            </>
          )}
        </div>
        <ModalMainContainer
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          isSubmitActive={isSubmitActive}
          modalMessage={modalMessage}
          isSubmitSuceess={isSubmitSuceess}
          reactivate={true}
          handleReactivate={handleReactivate}
          toggleToLandingPage={() => {
            setIsModalOpen(false);
            if (isRequesetComplete) {
              navigate("/");
            }
            if (isEditOrUpdateFailed) {
              dispatch({
                type: approvar_actionType.RESET_ERROR_MODEL
              });
            }
          }}
        />
        <Modal
          open={editSponser}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div style={ModalStyle}>
            <SelectedSponsorPopup
              onClose={handleClose}
              onSetSponsor={handleSetSponsor}
              loggedUser={
                completeState?.LoggedUserReducer?.loggedUser?.username
              }
              userMailId={userDetails?.email}
            />
          </div>
        </Modal>
      </div>
    </>
  );
}
