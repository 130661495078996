import { Button, Checkbox, FormControlLabel, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addorModifyRolesTrainingRequestCall } from '../../../../../../../redux/actions/adminAccount-actions/roles-action/rolesRequest-action';

const TrainingsTab = (props) => {
  const dispatch = useDispatch();
  const {mode,selectedRoleId,currentSelectedTrainings,allTrainings,setIsModalOpen,setActiveModalNotice} = props;
  const [trainingSelected, setSelectedTraining] = useState([]);
  const trainingChangeHandler = (event, training) => {
    const { checked } = event.target;
    const tempSelectedTraining = checked ? [...trainingSelected, training]
      : trainingSelected.filter(grpObj => grpObj.id !== training.id);
      setSelectedTraining(tempSelectedTraining);
  };

  useEffect(() => {
    if (mode) {
      switch (mode) {
        case "edit":
          setSelectedTraining(currentSelectedTrainings);
          break;
        case "create":
          setSelectedTraining([]);
          break;
        default:
          setSelectedTraining([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  const createModifyRoleTrainingHandler = () => {

    const payload = trainingSelected.map(grpObj => {
      return {
        vamTrainingId: grpObj.id
      };
    });
      switch (mode) {
        case "edit":
          dispatch(addorModifyRolesTrainingRequestCall("edit",payload,selectedRoleId));
          setIsModalOpen(false);
          setActiveModalNotice("message");
          break;
        case "create":
          dispatch(addorModifyRolesTrainingRequestCall("create",payload,selectedRoleId));
          setIsModalOpen(false);
          setActiveModalNotice("message");
          break;
        default: null;
      }
    };
  
    const cancelCreateModifyRoleTrainingHandler = () => {
      setIsModalOpen(false);
    };

  return (
    <div className='role_addOrModifyTrainings'>
      <h3>Select Trainings - {trainingSelected.length}</h3>
      <Grid container md={12} spacing={4} className='role_grpTrainingDacContent'>
        {allTrainings.map(trainingsObj => {
          return (
            <>
            <Grid key={trainingsObj.id} item md={4} className="role_addOrModifyCheckbox">
              <FormControlLabel sx={{ alignItems: 'flex-start' }} control={<Checkbox sx={{marginTop: -0.6}} checked={trainingSelected.find(trainingObj => trainingObj.id === trainingsObj.id) ? true : false} onChange={(event) => trainingChangeHandler(event,trainingsObj)} name={trainingsObj.trainingName}/>} label={trainingsObj.trainingName} />
            </Grid>
            </>
          );
        })}
      </Grid>
      <Grid container spacing={2} md={12} className="role_actionButons role_grpsTransDacsActionBtnsCommon">
        <Button variant="outlined" color="error" className='btnRole btnRoleCancel' onClick={cancelCreateModifyRoleTrainingHandler}>Cancel</Button>
        <Button variant="outlined" color="success" className='btnRole' onClick={createModifyRoleTrainingHandler}>Submit</Button>
      </Grid>
    </div>
  );
};

export default TrainingsTab;