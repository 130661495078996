import { Button, Checkbox, FormControlLabel, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addorModifyRolesDACRequestCall } from "../../../../../../../redux/actions/adminAccount-actions/roles-action/rolesRequest-action";

const DacRoleTab = (props) => {
  const dispatch = useDispatch();
  const {
    allDAC,
    selectedRoleId,
    setIsModalOpen,
    setActiveModalNotice,
    currentSelectedDacRoles,
    mode
  } = props;
  const [dacSelected, setSelectedDac] = useState([]);
  const dacChangeHandler = (event, dac) => {
    const { checked } = event.target;
    const tempSelectedDac = checked
      ? [...dacSelected, dac]
      : dacSelected.filter((dacObj) => dacObj.idPk !== dac.idPk);
    setSelectedDac(tempSelectedDac);
  };

  useEffect(() => {
    if (mode) {
      switch (mode) {
        case "edit":
          setSelectedDac(currentSelectedDacRoles);
          break;
        case "create":
          setSelectedDac([]);
          break;
        default:
          setSelectedDac([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  const createModifyRoleDACHandler = () => {
    const payload = dacSelected.map((grpObj) => {
      return {
        vamDacRoleIdPk: grpObj.idPk
      };
    });
    switch (mode) {
      case "edit":
        dispatch(
          addorModifyRolesDACRequestCall("edit", payload, selectedRoleId)
        );
        setIsModalOpen(false);
        setActiveModalNotice("message");
        break;
      case "create":
        dispatch(
          addorModifyRolesDACRequestCall("create", payload, selectedRoleId)
        );
        setIsModalOpen(false);
        setActiveModalNotice("message");
        break;
      default:
        null;
    }
  };

  const cancelCreateModifyRoleDACHandler = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="role_addOrModifyDAC">
      <h3>
        Select DAC Role (Role Name & Doc Type Group) - {dacSelected.length}
      </h3>
      <Grid
        container
        md={12}
        spacing={4}
        className="role_grpTrainingDacContent"
      >
        {allDAC.map((dacObj) => {
          let isCountryProductRequired;
          if (dacObj.addOnlyOneCountry?.length > 0) {
            isCountryProductRequired = dacObj.addProducts
              ? `${dacObj.addOnlyOneCountry} & Add Product`
              : dacObj.addOnlyOneCountry;
          } else if (dacObj.addCountries && dacObj.addProducts) {
            isCountryProductRequired = "Add Countries & Add Product";
          } else if (dacObj.addCountries) {
            isCountryProductRequired = "Add Countries";
          } else if (dacObj.addProducts) {
            isCountryProductRequired = "Add Products";
          } else {
            isCountryProductRequired = "N/A";
          }
          return (
            <>
              <Grid
                key={dacObj.idPk}
                item
                md={4}
                className="role_addOrModifyCheckbox"
              >
                <FormControlLabel
                sx={{ alignItems: 'flex-start' }}
                  control={
                    <Checkbox
                      sx={{marginTop: -0.6}}
                      checked={
                        dacSelected.find(
                          (dacObject) => dacObject.idPk === dacObj.idPk
                        )
                          ? true
                          : false
                      }
                      onChange={(event) => dacChangeHandler(event, dacObj)}
                      name={
                        dacObj.vvpmApplicationRoleName +
                        " & " +
                        dacObj.vvpmApplicationDocTypeGroup
                      }
                    />
                  }
                  label={`${dacObj.vvpmApplicationRoleName} & ${dacObj.vvpmApplicationDocTypeGroup}(${isCountryProductRequired})`}
                />
              </Grid>
            </>
          );
        })}
      </Grid>
      <Grid
        container
        spacing={2}
        md={12}
        className="role_actionButons role_grpsTransDacsActionBtnsCommon"
      >
        <Button
          variant="outlined"
          color="error"
          className="btnRole btnRoleCancel"
          onClick={cancelCreateModifyRoleDACHandler}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          color="success"
          className="btnRole"
          onClick={createModifyRoleDACHandler}
        >
          Submit
        </Button>
      </Grid>
    </div>
  );
};

export default DacRoleTab;
