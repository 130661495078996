import { Button, Checkbox, FormControlLabel, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addorModifyRolesGroupRequestCall } from '../../../../../../../redux/actions/adminAccount-actions/roles-action/rolesRequest-action';

const GroupsTab = (props) => {
  const dispatch = useDispatch();
  const { mode,selectedRoleId, currentSelectedGroups, allGroups, setIsModalOpen, setActiveModalNotice } = props;
  const [groupsSelected, setSelectedGroups] = useState([]);
  const groupChangeHandler = (event, group) => {
    const { checked } = event.target;
    const tempSelectedGroups = checked ? [...groupsSelected, group]
      : groupsSelected.filter(grpObj => grpObj.idPk !== group.idPk);
    setSelectedGroups(tempSelectedGroups);
  };

  useEffect(() => {
    if (mode) {
      switch (mode) {
        case "edit":
          setSelectedGroups(currentSelectedGroups);
          break;
        case "create":
          setSelectedGroups([]);
          break;
        default:
          setSelectedGroups([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  console.log(groupsSelected);

  const createModifyRoleGroupHandler = () => {

  const payload = groupsSelected.map(grpObj => {
    return {
      vamGroupIdPk: grpObj.idPk
    };
  });
    switch (mode) {
      case "edit":
        dispatch(addorModifyRolesGroupRequestCall("edit",payload,selectedRoleId));
        setIsModalOpen(false);
        setActiveModalNotice("message");
        break;
      case "create":
        dispatch(addorModifyRolesGroupRequestCall("create",payload,selectedRoleId));
        setIsModalOpen(false);
        setActiveModalNotice("message");
        break;
      default: null;
    }
  };

  const cancelCreateModifyRoleGroupHandler = () => {
    setIsModalOpen(false);
  };

  return (
    <div className='role_addOrModifyGroups'>
      <h3>Select Groups  - {groupsSelected.length}</h3>
      <Grid container md={12} spacing={4} className='role_grpTrainingDacContent'>
        {allGroups.map(groupObj => {
          return (
            <>
              <Grid key={groupObj.idPk} item md={4} className="role_addOrModifyCheckbox">
                <FormControlLabel sx={{ alignItems: 'flex-start' }} control={<Checkbox sx={{marginTop: -0.6}} checked={groupsSelected.find(grpSelectedObj => grpSelectedObj.idPk === groupObj.idPk) ? true : false} onChange={(event) => groupChangeHandler(event, groupObj)} name={groupObj.name} />} label={groupObj.name} />
              </Grid>
            </>
          );
        })}
      </Grid>
      <Grid container spacing={2} md={12} className="role_actionButons role_grpsTransDacsActionBtnsCommon">
        <Button variant="outlined" color="error" className='btnRole btnRoleCancel' onClick={cancelCreateModifyRoleGroupHandler}>Cancel</Button>
        <Button variant="outlined" color="success" className='btnRole' onClick={createModifyRoleGroupHandler}>Submit</Button>
      </Grid>
    </div>
  );
};

export default GroupsTab;